import React from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as cartActions from '../store/actions';
import SiteLayout from '../components/SiteLayout';
import { MAX_WIDTH } from '../utils/constants';
import { ThumbNav } from '../components/common';
import { Editor, Steps } from '../components/dynamicContent';
import { flattenStrapiData, formatCurrency } from '../utils/formatter';
import RelatedProducts from '../components/page/ProductPage/RelatedProducts';
import RelatedProductTypes from '../components/page/ProductPage/RelatedProductTypes';
import BannerTextWithBreadcrum from '../components/common/BannerTextWithBreadcrum';

const ProductItemWrapper = styled.div`
  margin-top: 68px;
  width: 100%;
  max-width: ${MAX_WIDTH};
  display: flex;
  flex-direction: column;
  align-items: center;
  .item-price {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #000000;
  }
  .item-price-unitprice {
    text-decoration-line: line-through;
    margin-right: 20px;
  }
  .product-item-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .product-item-content-name {
    margin-bottom: 35px;
  }
  .editor-wrapper {
    margin-top: 54px;
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #292929;
    strong {
      color: #a88c69;
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    .product-item-main {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .product-item-content {
      margin-top: 46px;
      margin-left: 25px;
    }
    .product-item-content-name {
      margin-bottom: 19px;
    }
  }
`;

// Todo: update margin-top with global variable navbar_height
const ProductBannerWrapper = styled.div`
  width: 100%;
  margin-top: 88px;
  @media screen and (max-width: ${MAX_WIDTH}) {
    margin-top: 65px;
  }
`;

const Price = ({ unit_price, discounted_price }) => {
  return discounted_price ? (
    <div className="item-price">
      <span className="item-price-unitprice">{formatCurrency(unit_price)}</span>
      <span className="item-price-discountprice">{formatCurrency(discounted_price)}</span>
    </div>
  ) : (
    <h6 className="item-price">{formatCurrency(unit_price)}</h6>
  );
};

function Product(props) {
  const {
    data: { strapiProduct: productData, allStrapiProduct: relatedProducts, allStrapiProductType: productTypes },
    addItem,
  } = props;
  const {
    id,
    name,
    slug,
    description,
    unit_price,
    discounted_price,
    product_images,
    product_thumbnail,
    product_content,
    product_type,
  } = productData;
  const breadcrumTree = [
    {
      id: 0,
      name: 'Trang chủ',
      slug: '/',
    },
    {
      id: 1,
      name: 'Sản phẩm',
      slug: '/product',
    },
    {
      id: 2,
      name: product_type?.name,
      slug: product_type?.url,
    },
    {
      id: 3,
      name: name,
    },
  ];
  const contentEditor = product_content.find((content) => content.strapi_component === 'content.editor');
  const contentSteps = product_content.find((content) => content.strapi_component === 'content.steps');
  const isMobile = useMediaQuery({ query: `(max-width: ${MAX_WIDTH})` });

  const LAYOUT_KEY = 'PRODUCT';
  return (
    <SiteLayout activeKey={LAYOUT_KEY}>
      <ProductBannerWrapper>
        <BannerTextWithBreadcrum title="lão tửu hạ thổ" breadcrumTree={breadcrumTree} />
      </ProductBannerWrapper>
      <ProductItemWrapper>
        <div className="product-item-main">
          <div className="product-item-images">
            <ThumbNav images={product_images} />
          </div>
          <div className="product-item-content">
            <h1 className="product-item-content-name">{name}</h1>
            <Price unit_price={unit_price} discounted_price={discounted_price} />
            <div className="editor-wrapper">{contentEditor ? <Editor content={contentEditor} /> : null}</div>
          </div>
        </div>
        {contentSteps && !isMobile ? <Steps content={contentSteps} /> : null}
        <RelatedProducts relatedProducts={flattenStrapiData(relatedProducts)} productTypeSlug={product_type?.url} />
      </ProductItemWrapper>
    </SiteLayout>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addItem: (item) => dispatch(cartActions.addItem(item)),
  };
};

export default connect(null, mapDispatchToProps)(Product);

Product.propTypes = {
  data: PropTypes.any,
  addItem: PropTypes.func,
};

export const query = graphql`
  query Products($id: String!, $product_type_id: String!) {
    strapiProduct(id: { eq: $id }) {
      id
      name
      slug
      description
      unit_price
      discounted_price
      product_images {
        url
      }
      product_thumbnail {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      product_content
      product_type {
        id
        name
        url
      }
    }
    allStrapiProduct(filter: { product_type: { id: { eq: $product_type_id } } }) {
      edges {
        node {
          id
          name
          subname
          unit_price
          discounted_price
          slug
          product_thumbnail {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
    allStrapiProductType {
      edges {
        node {
          id
          name
          description
          url
          producttype_thumbnail {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
